<template>
    <section class="pt-3 overflow-auto custom-scroll" style="height:calc(100vh - 211px)">
        <div class="row mx-0 py-2">
            <p class="col-12 px-3 text-general f-22 f-600">
                Parámetros de configuración
            </p>
        </div>
        <div class="row mx-0">
            <div class="col">
                <div class="row mx-0 py-3 bg-white br-12 mb-2">
                    <p class="col-12 text-general f-15 px-5 f-500">
                        Centro de Distribución
                    </p>
                    <div class="col-12 px-0">
                        <div class="row mx-0 px-4 my-2">
                            <div class="f-16 py-2 px-3 d-middle br-8 bg-fondo border text-general" style="min-width:200px;max-width:280px;height:32px;">
                                <i class="icon-warehouse f-20" />
                                <p class="tres-puntos ml-3">
                                    Cedis: {{ tienda.nombre_cedis }}
                                </p>
                            </div>
                            <el-tooltip v-if="$can('botones_leecheros_gestionar_cedis')" placement="bottom" content="Cambiar Cedis" effect="light">
                                <div class="btn-action border ml-2">
                                    <i v-if="$can('botones_leecheros_gestionar_cedis')" class="icon-pencil-outline f-17 cr-pointer ml-1" @click="cambiarCedis" />
                                </div>
                            </el-tooltip>
                        </div>
                    </div>
                </div>
                <div class="row mx-0 py-3 br-12 my-2 bg-white">
                    <p class="col-12 px-0 text-general f-15 px-5 f-500">
                        Compras
                    </p>
                    <div class="col-12 px-0">
                        <div class="row align-items-center mx-0 px-4 my-3">
                            <el-checkbox v-model="tienda.cliente_app" :disabled="!$can('botones_leecheros_gestionar_cedis')" class="check-red" @change="updateCompras($event, 'cliente_app')">
                                <span class="ml-2 text-general">
                                    Comprar para vender a {{ $config.cliente }} registrados
                                </span>
                            </el-checkbox>
                            <el-tooltip placement="bottom" effect="light">
                                <div slot="content">
                                    <div style="max-width:280px">
                                        <p align="justify">Puede vender para los {{ $config.cliente }} que se han registrado a través de la aplicación.</p>
                                    </div>
                                </div>
                                <i class="icon-info-circled-alt f-18 text-general2 cr-pointer" />
                            </el-tooltip>
                        </div>
                        <div class="row align-items-center mx-0 px-4 my-3">
                            <el-checkbox v-model="tienda.cliente_digitado" :disabled="!$can('botones_leecheros_gestionar_cedis')" class="check-red" @change="updateCompras($event, 'cliente_digitado')">
                                <span class="ml-2 text-general">
                                    Comprar para vender a {{ $config.cliente }} no registrados
                                </span>
                            </el-checkbox>
                            <el-tooltip placement="bottom" effect="light">
                                <div slot="content">
                                    <div style="max-width:280px">
                                        <p align="justify">Puede vender para los {{ $config.cliente }} que no se han registrado a través de la aplicación, el {{ $config.vendedor }} debe ingresar los datos del cliente de manera manual.</p>
                                    </div>
                                </div>
                                <i class="icon-info-circled-alt f-18 text-general2 cr-pointer" />
                            </el-tooltip>
                        </div>
                        <div class="row align-items-center mx-0 px-4 my-3">
                            <el-checkbox v-model="tienda.cliente_yo" :disabled="!$can('botones_leecheros_gestionar_cedis')" class="check-red" @change="updateCompras($event, 'cliente_yo')">
                                <span class="ml-2 text-general">
                                    Comprar para el {{ $config.vendedor }} mismo
                                </span>
                            </el-checkbox>
                            <el-tooltip placement="bottom" effect="light">
                                <div slot="content">
                                    <div style="max-width:280px">
                                        <p align="justify">El {{ $config.vendedor }} puede comprar para si mismo, ésta opción le permite aplicar cupones si están disponibles para él.</p>
                                    </div>
                                </div>
                                <i class="icon-info-circled-alt f-18 text-general2 cr-pointer" />
                            </el-tooltip>
                        </div>
                    </div>
                </div>
                <div class="row mx-0 py-4 bg-white my-3 br-12">
                    <div class="col-12 text-general f-15 px-5 f-500">
                        Cambio de precio
                    </div>
                    <div class="col-12">
                        <div class="row align-items-center mx-0 px-4 my-3">
                            <el-checkbox v-model="tienda.permiso_descuento" :disabled="!$can('botones_leecheros_gestionar_cedis')" class="check-red" @change="updatePrecioSugerido">
                                <span class="ml-2 text-general">
                                    Precio sugerido
                                </span>
                            </el-checkbox>
                            <el-tooltip placement="bottom" effect="light">
                                <div slot="content">
                                    <div style="max-width:280px">
                                        <p align="justify">Este {{$config.vendedor}} podrá sugerir un nuevo valor de los productos de un Pedido de sus Clientes, generando una solicitud de aprobación al Centro de distribución.</p>
                                    </div>
                                </div>
                                <i class="icon-info-circled-alt f-18 text-general2 cr-pointer" />
                            </el-tooltip>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="row mx-0 bg-white br-12 py-3">
                    <p class="col-12 text-general f-15 px-5 f-500">
                        Tipo de vendedor
                    </p>
                    <div class="col px-0">
                        <div class="row mx-0 px-4 my-2 py-2">
                            <div class="f-16 py-2 px-3 d-middle-center br-8 text-general" style="min-width:200px;max-width:380px;height:32px;background-color:#F6F9FB;">
                                <i class="icon-playlist-check f-20" />
                                <p class="py-1">
                                    Tipo: {{ tienda.nombre_tipo || 'Aún no se ha asignado un tipo' }}
                                </p>
                            </div>
                            <el-tooltip placement="bottom" content="Cambiar Tipo" effect="light">
                                <div class="btn-action border ml-2">
                                    <i class="icon-pencil-outline f-17" @click="cambiarCondicion" />
                                </div>
                            </el-tooltip>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="row mx-0 px-4 my-3 align-items-center">
                            <!-- <el-checkbox v-model="tienda.cambio_condiciones" :disabled="!$can('botones_leecheros_gestionar_cedis')" class="check-red" @change="updateCambioCondiciones">
                                <div class="col text-general pr-0 pl-2">
                                    <p class="rompe-palabras">
                                        Cliente puede cambiar la condición en el pedido de abastecimiento
                                    </p>
                                </div>
                            </el-checkbox> -->
                            <!-- <el-tooltip placement="bottom" effect="light">
                                <div slot="content">
                                    <div style="max-width:280px">
                                        <p align="justify">El {{$config.vendedor}} podrá cambiar en cada pedido la condición de Comisión aplicada, de lo contrario siempre será el default que definió el administrador.</p>
                                    </div>
                                </div>
                                <i class="icon-info-circled-alt f-18 text-general2 cr-pointer" />
                            </el-tooltip> -->
                        </div>
                    </div>
                </div>

                <div class="row mx-0 my-3 py-3 bg-white br-12">
                    <p class="col-12 text-general f-15 px-5 f-500">
                        Descuento de lista por default
                    </p>
                    <div class="col px-0">
                        <div class="row mx-0 mt-4">
                            <div class="col">
                                <div class="row mx-0">
                                    <div class="col-auto">
                                        <p class="text-general f-15 ml-2">
                                            El vendedor puede aplicar a los beneficios de descuento de lista que ofrece el cedis.
                                        </p>
                                    </div>
                                </div>
                                <div class="row mx-2 mt-3">
                                    <div class="col-auto d-middle text-general">
                                        <input v-model="tienda.descuento_lista" :value="1" type="radio" class="option-input radio black" @change="updateDescuentosLista('descuento_lista', tienda.descuento_lista)" />
                                        <span class="ml-14 f-15">
                                            De manera automatica
                                        </span>
                                    </div>
                                </div>
                                <div class="row mx-2 mt-3">
                                    <div class="col-auto d-middle text-general">
                                        <input v-model="tienda.descuento_lista" :value="2" type="radio" class="option-input radio black" @change="updateDescuentosLista('descuento_lista', tienda.descuento_lista)" />
                                        <span class="ml-14 f-15">
                                            De manera manual
                                        </span>
                                    </div>
                                </div>

                                <div v-if="tienda.descuento_lista === 2" class="row mx-5 mt-2">
                                    <div class="col-auto">
                                        <p class="f-12 text-general ml-2">Clasificación</p>
                                        <el-select v-model="tienda.id_categoria_lista" size="small" placeholder="Seleccionar" @change="updateDescuentosLista('id_categoria_lista', tienda.id_categoria_lista)">
                                            <el-option
                                            v-for="item in listaClasificacion"
                                            :key="item.id"
                                            :label="item.nombre"
                                            :value="item.id"
                                            />
                                        </el-select>
                                        <div>
                                            <span v-if="tienda.descuento_lista && !tienda.id_categoria_lista" class="text-danger w-100 f-11">
                                                Complete la selección escogiendo una clasificación
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="row mx-2 mt-3">
                                    <div class="col-auto d-middle text-general">
                                        <input v-model="tienda.descuento_lista" :value="0" type="radio" class="option-input radio black" @change="updateDescuentosLista('descuento_lista', tienda.descuento_lista)" />
                                        <span class="ml-14 f-15">
                                            No acogerlo al beneficio.
                                        </span>
                                    </div>
                                </div>
                                <!-- <div class="row mx-2 mt-3">
                                    <div class="col-auto d-middle text-general">
                                        <el-checkbox v-model="tienda.descuento_lista_clientes" :true-label="1" :false-label="0" class="check-red" @change="updateDescuentosLista('descuento_lista_clientes', tienda.descuento_lista_clientes)" />
                                        <span class="ml-3 f-15">
                                            Los clientes del vendedor pueden acogerse al beneficio de descuento de lista
                                        </span>
                                    </div>
                                </div> -->
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row mx-0 my-3 py-3 bg-white br-12">
                    <div class="col-12 text-general f-15 px-5 f-500">
                        Suministro y entrega
                    </div>
                    <div class="col-12">
                        <template v-if="$can('botones_leecheros_gestionar_cedis')">
                            <div class="row mx-0 align-items-center px-4 my-3">
                                <el-checkbox v-model="tienda.cliente_pedido_directo" :disabled="!$can('botones_leecheros_gestionar_cedis')" class="check-red" @change="updateClientePedido">
                                    <span class="ml-2 text-general">
                                        {{ $config.cliente }} pedido directo
                                    </span>
                                </el-checkbox>
                                <el-tooltip placement="bottom" effect="light">
                                    <div slot="content">
                                        <div style="max-width:280px">
                                            <p align="justify">Los pedidos que hagan los {{ $config.cliente + 's' }} de este {{ $config.vendedor }} serán atendidos directamente por el Centro de distribución y este se encargará de realizar el cambio de estos y gestión de productos hasta el momento del envío.</p>
                                        </div>
                                    </div>
                                    <i class="icon-info-circled-alt f-18 text-general2 cr-pointer" />
                                </el-tooltip>
                            </div>
                            <div class="ml-5" :class="{'disabled-div': !tienda.cliente_pedido_directo}">
                                <div class="row align-items-center mx-0 px-4 my-3">
                                    <el-checkbox v-model="tienda.cliente_entrega_directa" :disabled="!$can('botones_leecheros_gestionar_cedis')" class="check-red" @change="updateEntregaDirecta">
                                        <span class="ml-2 text-general">
                                            {{ $config.cliente }} entrega directa
                                        </span>
                                    </el-checkbox>
                                    <el-tooltip placement="bottom" content="Texto..." effect="light">
                                        <div slot="content">
                                            <div style="max-width:280px">
                                                <p align="justify">Activa esta opción cuando el {{ $config.vendedor }} no hace las entregas de los pedidos a sus {{ $config.cliente + 's' }}, sino que el Centro de Distribución se encarga de llevarlo directamente hasta la casa del {{ $config.cliente }}.</p>
                                            </div>
                                        </div>
                                        <i class="icon-info-circled-alt f-18 text-general2 cr-pointer" />
                                    </el-tooltip>
                                </div>
                                <div class="row align-items-center mx-0 px-4 my-3">
                                    <el-checkbox v-model="tienda.cambio_tipo_pedido" :disabled="!$can('botones_leecheros_gestionar_cedis')" class="check-red" @change="updateCambioTipoPedido">
                                        <span class="ml-2 text-general">
                                            Permiso cambio tipo de suministro y entrega
                                        </span>
                                    </el-checkbox>
                                    <el-tooltip placement="bottom" effect="light">
                                        <div slot="content">
                                            <div style="max-width:280px">
                                                <p align="justify">El {{$config.vendedor}} podrá cambiar la configuración de Pedidos_directo y Entrega_directa en su pedido, de lo contrario siempre será el default que definió el administrador.</p>
                                            </div>
                                        </div>
                                        <i class="icon-info-circled-alt f-18 text-general2 cr-pointer" />
                                    </el-tooltip>
                                </div>
                            </div>
                            <div class="row align-items-center mx-0 px-4 my-3">
                                <el-checkbox v-model="tienda.manejo_inventario" :disabled="!$can('botones_leecheros_gestionar_cedis')" class="check-red" @change="updateManejoInventario">
                                    <span class="ml-2 text-general">Manejar inventario</span>
                                </el-checkbox>
                                <el-tooltip placement="bottom" effect="light">
                                    <div slot="content">
                                        <div style="max-width:280px">
                                            <p align="justify">Activa esta opción cuando el {{$config.vendedor}} maneje inventario en su casa, así se podrá controlar exactamente que tiene en stock, además servirá para que no despache a sus clientes productos si no tiene la cantidad adecuada en su inventario.</p>
                                        </div>
                                    </div>
                                    <i class="icon-info-circled-alt f-18 text-general2 cr-pointer" />
                                </el-tooltip>
                            </div>
                            <!-- nuevos -->
                            <div class="ml-5" :class="{'disabled-div': !tienda.manejo_inventario}">
                                <div class="row align-items-center mx-0 px-4 my-3">
                                    <el-checkbox v-model="tienda.editar_precios_inventario" :disabled="!$can('botones_leecheros_gestionar_cedis')" class="check-red" @change="updateCompras($event, 'editar_precios_inventario')">
                                        <span class="ml-2 text-general">
                                            Cambio de valor de producto de venta
                                        </span>
                                    </el-checkbox>
                                    <el-tooltip placement="bottom" effect="light">
                                        <div slot="content">
                                            <div style="max-width:280px">
                                                <p align="justify">El {{$config.vendedor}} podrá cambiar el valor del venta de un producto especifico o definir un porcentaje de aumento o disminución que será aplicado a todos los productos.</p>
                                            </div>
                                        </div>
                                        <i class="icon-info-circled-alt f-18 text-general2 cr-pointer" />
                                    </el-tooltip>
                                </div>
                                <div class="row align-items-center mx-0 px-4 my-3">
                                    <el-checkbox v-model="tienda.usar_venta_stock_cedis" :disabled="!$can('botones_leecheros_gestionar_cedis')" class="check-red" @change="updateCompras($event, 'usar_venta_stock_cedis')">
                                        <span class="ml-2 text-general">
                                            Ventas sin stock del cedis
                                        </span>
                                    </el-checkbox>
                                    <el-tooltip placement="bottom" effect="light">
                                        <div slot="content">
                                            <div style="max-width:280px">
                                                <p align="justify">El {{$config.vendedor}} podrá ofrecer todos los productos del cedis que se pueden vender sin stock.</p>
                                            </div>
                                        </div>
                                        <i class="icon-info-circled-alt f-18 text-general2 cr-pointer" />
                                    </el-tooltip>
                                </div>
                                <div class="row align-items-center mx-0 px-4 my-3">
                                    <el-checkbox v-model="tienda.vender_no_existentes" :disabled="!$can('botones_leecheros_gestionar_cedis')" class="check-red" @change="updateCompras($event, 'vender_no_existentes')">
                                        <span class="ml-2 text-general">
                                            Vender productos del inventario que no vende el cedis
                                        </span>
                                    </el-checkbox>
                                    <el-tooltip placement="bottom" effect="light">
                                        <div slot="content">
                                            <div style="max-width:280px">
                                                <p align="justify">Puede vender sus productos en stock aún cuando en ese momento el cedis no lo tenga disponible.</p>
                                            </div>
                                        </div>
                                        <i class="icon-info-circled-alt f-18 text-general2 cr-pointer" />
                                    </el-tooltip>
                                </div>
                            </div>
                            <div class="row align-items-center mx-0 px-4 my-3">
                                <el-checkbox v-model="tienda.promociones_cedis" :disabled="!$can('botones_leecheros_gestionar_cedis')" class="check-red" @change="updateCompras($event, 'promociones_cedis')">
                                    <span class="ml-2 text-general">
                                        Usar las promociones del Cedis
                                    </span>
                                </el-checkbox>
                                <el-tooltip placement="bottom" effect="light">
                                    <div slot="content">
                                        <div style="max-width:280px">
                                            <p align="justify">Usar las promociones del Cedis.</p>
                                        </div>
                                    </div>
                                    <i class="icon-info-circled-alt f-18 text-general2 cr-pointer" />
                                </el-tooltip>
                            </div>
                            <!-- Cobertura -->
                            <div class="row align-items-center mx-0 px-4 my-3">
                                <el-checkbox v-model="tienda.cambiar_cobertura" :disabled="!$can('botones_leecheros_gestionar_cedis')" class="check-red" @change="updateCompras($event, 'cambiar_cobertura')">
                                    <span class="text-general">
                                        Cambiar cobertura de entrega
                                    </span>
                                </el-checkbox>
                                <el-tooltip placement="bottom" effect="light">
                                    <div slot="content">
                                        <div style="max-width:280px">
                                            <p align="justify">Activa esta opción cuando el {{$config.vendedor}} puede cambiar la cobertura de la entrega de los pedidos para sus clientes.</p>
                                        </div>
                                    </div>
                                    <i class="icon-info-circled-alt f-18 text-general2 cr-pointer" />
                                </el-tooltip>
                            </div>
                        </template>
                    </div>
                </div>
            </div>
        </div>
        <!-- Partials -->
        <modalCambiarCedis ref="modalCambiarCedis" @actualizar="getDataTienda" />
        <modalEditarCondicion ref="modalEditarCondicion" @actualizar="getDataTienda" />
    </section>
</template>

<script>
import Produccion from "../../../../services/tiendas/produccion";
import CambioDatos from "~/services/tiendas/cambio_datos";
import Cedis from "@/services/cedis/index"
import { mapGetters } from 'vuex';

export default {
    components: {
        modalCambiarCedis: () => import('../partials/modalCambiarCedis'),
        modalEditarCondicion: () => import('../partials/modalEditarCondicion'),
    },
    data(){
        return{
            listaClasificacion: [],
        }
    },
    computed: {
        id_tienda(){
            return Number(this.$route.params.id_tienda)
        },
        ...mapGetters({
            tienda: 'tiendas/info_cabecera/parametros'
        }),
    },
    mounted(){
        this.getClasificaciones();
        this.info_tienda()
    },
    methods: {
        async info_tienda(){
            try {
                const { data } = await Produccion.info_tienda(this.$route.params.id_tienda)
                this.$store.commit('tiendas/info_cabecera/setParametros', data.info)
                await this.$store.dispatch('tiendas/info_cabecera/action_info_cabecera', this.$route.params.id_tienda)
            } catch (error){
                this.error_catch(error)
            }
        },
        cambiarCedis(){
            this.$refs.modalCambiarCedis.toggle(this.tienda)
        },
        async getDataTienda(){
            try{
                const { data } = await Produccion.info_tienda(this.$route.params.id_tienda);
                this.$store.commit('tiendas/info_cabecera/setParametros', data.info)
            }catch(error){
                this.error_catch(error)
            }
        },
        cambiarCondicion(){
            this.$refs.modalEditarCondicion.toggle(this.tienda)
        },
        async updateClientePedido(){
            try {
                let params = {
                    id_tienda: this.id_tienda,
                    valor: this.tienda.cliente_pedido_directo
                }

                const {data} = await CambioDatos.updateClientePedido(params)

                if(!this.tienda.cliente_pedido_directo){
                    this.tienda.cliente_entrega_directa = false
                    this.tienda.cambio_tipo_pedido = false
                }

                this.notificacion('Mensaje', 'Informacion actualizada', 'success')

            } catch (e){
                this.error_catch(e)
            }
        },
        async updateEntregaDirecta(){
            try {
                const {data} = await CambioDatos.updateEntregaDirecta(this.id_tienda)
                this.notificacion('Mensaje', 'Informacion actualizada', 'success')

            } catch (e){
                this.error_catch(e)
            }
        },
        async updateCambioTipoPedido(){
            try {
                const {data} = await CambioDatos.updateCambioTipoPedido(this.id_tienda)
                this.notificacion('Mensaje', 'Informacion actualizada', 'success')

            } catch (e){
                this.error_catch(e)
            }
        },
        async updatePrecioSugerido(){
            try {
                const {data} = await CambioDatos.updatePrecioSugerido(this.id_tienda)
                this.notificacion('Mensaje', 'Informacion actualizada', 'success')

            } catch (e){
                this.error_catch(e)
            }
        },
        async updateCambioCondiciones(){
            try {
                const {data} = await CambioDatos.updateCambioCondiciones(this.id_tienda)
                this.notificacion('Mensaje', 'Informacion actualizada', 'success')

            } catch (e){
                this.error_catch(e)
            }
        },
        async updateManejoInventario(){
            try {

                let params = {
                    id_tienda: this.id_tienda,
                    valor: this.tienda.manejo_inventario
                }

                const {data} = await CambioDatos.updateManejoInventario(params)

                if(!this.tienda.manejo_inventario){
                    this.tienda.editar_precios_inventario = false
                    this.tienda.usar_venta_stock_cedis = false
                    this.tienda.vender_no_existentes = false
                }

                this.notificacion('Mensaje', 'Informacion actualizada', 'success')

            } catch (e){
                this.error_catch(e)
            }
        },
        async updateVentasSoloApp(){
            try {
                const {data} = await CambioDatos.updateVentasSoloApp(this.id_tienda)
                this.notificacion('Mensaje', 'Informacion actualizada', 'success')

            } catch (e){
                this.error_catch(e)
            }
        },
        async updateCompras(valor, campo){
            try {
                const obj = {
                    valor,
                    campo,
                }
                const { data } = await CambioDatos.updateCompras(this.id_tienda, obj)
                this.notificacion('Mensaje', 'Informacion actualizada', 'success')

            } catch (error){

                this.error_catch(error)
            }
        },
        async updateDescuentosLista(column, value){
            try {
                const payload = {
                    column: column,
                    value: value
                };
                const {data} = await CambioDatos.putParametroDescuentosLista(this.id_tienda, payload);
                if(data.exito){
                    if(column === 'descuento_lista' && (parseInt(value) === 0 || parseInt(value) === 1)){
                        this.tienda.id_categoria_lista = null;
                    }
                    this.notificacion('Mensaje', 'Información actualizada', 'success');
                }
            } catch(error){
                this.error_catch(error)
            }
        },
        async getClasificaciones(){
            try {
                const {data} = await Produccion.getClasificacionesTendero(this.id_tienda);
                this.listaClasificacion = data.data;
            } catch(e){
                this.error_catch(e)
            }
        }
    }
}
</script>
