<template>
    <section class="pt-2">
        <div class="row mx-0">
            <div class="px-3 bg-white br-t-12" style="width:410px;">
                <div class="row justify-center py-3 border-bottom">
                    <div class="input" style="width:366px;">
                        <el-input v-model="buscarTienda" class="w-100 br-20" :placeholder="`Buscar ${$config.vendedor}`" />
                    </div>
                </div>
            </div>
            <div class="col px-0 ml-3 bg-white br-t-12">
                <div v-if="activo > -1" class="row mx-0 py-3 pl-2" style="height:73px;">
                    <div class="mx-2  d-middle-center text-general f-17">
                        Postulado {{ detalle.fecha_postulacion | helper-fecha('DD MMM Y') }}
                    </div>
                    <div class="f-14 text-general2 d-middle-center">
                        Hace {{ detalle.dias }} Días
                    </div>
                    <el-tooltip class="item" effect="light" content="Chat de soporte" placement="bottom">
                        <div class="btn-action cr-pointer bg-white br-8 ml-auto">
                            <chatIconoChat v-if="chatActivo" :id-tienda="detalle.id" />
                        </div>
                    </el-tooltip>
                    <div class="btn-outline d-middle-center text-general f-14 p-1 px-4 br-8 border mx-2 cr-pointer" @click="AbrirDenegarPostulacion()">
                        Denegar
                    </div>
                    <div class="btn-general bg-general d-middle-center text-white f-14 p-1 px-4 br-8 mx-2 cr-pointer" @click="AbrirAprobarPostulacion()">
                        Aprobar
                    </div>
                </div>
            </div>
        </div>
        <div class="row mx-0">
            <div class="px-0" style="width:410px;">
                <div class="overflow-auto custom-scroll bg-white br-b-12" style="width:100%;height:calc(100vh - 222px);">
                    <div
                    v-for="(t, idx) in tiendas.filter(el => !buscarTienda || (el.propietario_nombre && el.propietario_nombre.toLowerCase().includes(buscarTienda.toLowerCase())) || (el.telefono && (String(el.telefono).includes(buscarTienda))) )"
                    :key="idx"
                    class="row mx-0 d-middle border-bottom cr-pointer"
                    style="height:120px;"
                    @click="get_detalle(t.id), activo = idx"
                    >
                        <div :class="`mx-2 px-1 h-80 br-5 ${activo==idx?'bg-general':''}`" />
                        <div class="col px-0 tres-puntos f-15">
                            <div class="row mx-0 f-600">
                                <p class="tres-puntos text-general f-600">{{ t.propietario_nombre ? t.propietario_nombre : '--------' }}</p>
                            </div>
                            <div class="row mx-0">
                                <p class="mr-2 text-general">
                                    {{ t.fecha_postulacion | helper-fecha('DD/MMM/Y') }}
                                </p>
                                <div class="bg-general3 text-white px-2 f-14 br-12 d-middle-center" style="height:24px;">
                                    {{ t.dias }} Dias
                                </div>
                            </div>
                            <div class="row mx-0 text-general">
                                <div class="col pl-0 tres-puntos">
                                    <strong>{{ t.pais ? t.pais : '----' }}, </strong>{{ t.ciudad ? t.ciudad : '----' }}
                                </div>
                            </div>
                            <div class="row mx-0">
                                <div class="col pl-0 tres-puntos text-general">
                                    <strong>Telefono: </strong>{{ t.telefono }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <content-loader v-show="loading" />
                </div>
            </div>
            <div class="col px-0 ml-3">
                <div v-if="activo > -1" class="overflow-auto custom-scroll" style="width:100%;height:calc(100vh - 250px);">
                    <div class="row mx-0">
                        <div class="col-12 px-0">
                            <div class="row mx-0 my-2 bg-white br-12 py-3 px-2">
                                <img :src="detalle.imagen" class="br-12 border obj-cover" style="width:162px;height:162px;" />
                                <div class="col">
                                    <p class="mb-0 text-general f-600 my-3 f-22">
                                        {{ detalle.propietario_nombre ? detalle.propietario_nombre : 'No registra' }}
                                    </p>
                                    <p class="text-general mt-2">
                                        <strong> Fecha nacimiento: </strong>
                                        {{ detalle.propietario_nacimiento }}
                                    </p>
                                    <p class="text-general mt-2">
                                        <strong> Genero: </strong>
                                        {{ detalle.genero_nombre }}
                                    </p>
                                </div>
                            </div>
                            <div class="row mx-0 my-3 bg-white br-12 py-3 px-3">
                                <p class="col-12 text-general mt-2">
                                    <strong> Contacto </strong>
                                </p>
                                <p class="col-12 text-general mt-2">
                                    <strong> Número de telefono: </strong>
                                    {{ detalle.telefono }}
                                </p>
                                <p class="col-12 text-general mt-2">
                                    <strong>Dirección de correo electrónico: </strong>
                                    {{ detalle.correo }}
                                </p>
                                <div class="col-auto mt-2 f-600 text-general">
                                    Descripción:
                                </div>
                                <div class="col-9 mx-1 mt-2 text-general">
                                    {{ detalle.descripcion }}
                                </div>
                            </div>

                            <div class="row mx-0 mt-3 mb-2 bg-white br-12 py-2">
                                <div class="col">
                                    <p class="text-general mt-2 text-center">
                                        <i class="icon-map-marker-outline text-general" />
                                        {{ detalle.direccion }}
                                    </p>
                                    <div class="row mx-0 mt-2 justify-center text-general f-14">
                                        <div class="col-auto text-center">
                                            <strong> Complemento: </strong>
                                            {{ _.defaultTo(detalle.direccion_anexo,'No registra') }}
                                        </div>
                                        <div class="col-auto text-center">
                                            <strong> Barrio: </strong>
                                            {{ _.defaultTo(detalle.barrio,'No registra') }}
                                        </div>
                                        <div class="col-auto text-center">
                                            <strong> Cobertura: </strong>
                                            {{ agregarSeparadoresNumero(detalle.cobertura) }} metros
                                        </div>
                                    </div>
                                    <!--
                                        <p class="text-general mt-2">
                                        <strong> {{ detalle.pais }}, </strong>
                                        {{ detalle.ciudad }}
                                    </p> -->
                                    <div class="row mx-0 position-relative my-3">
                                        <div class="col p-0 px-0 cr-pointer" @click="abrirModalMapa">
                                            <mapa ref="mapaPeq" :coordenadas="coordenadas" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <template v-if="detalle.nombre">
                                <div class="row mx-0 mt-3 bg-white br-12 py-3">
                                    <div class="col-12 px-0">
                                        <p class="text-general mt-2">
                                            <strong> Tienda: </strong>
                                            {{ detalle.nombre }}
                                        </p>
                                        <p class="text-general mt-2">
                                            <strong> N.I.T de la tienda: </strong>
                                            {{ _.defaultTo(detalle.nit,'No registra') }}
                                        </p>
                                        <div v-if="detalle.nit_soporte" class="row mx-0 px-5 my-2 mt-3">
                                            <div id="df_gbp" class="bg-gr-general br-12 d-middle-center text-white px-5 cr-pointer" :class="file_type(detalle.nit_soporte) === 'application/pdf' ? '_df_custom' : ''" style="height:40px;width:380px;" @click="openAdjunto(tienda)">
                                                <i class="icon-doc-inv" />
                                                Adjunto: Soporte Nit
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </template>
                            <div class="row mx-0 bg-white br-12 py-2 my-3">
                                <p class="col-12 text-general f-18 f-600">Galería</p>
                                <div v-for="(img,index2) in detalle.imagenes" :key="index2" class="col">
                                    <img :src="img.imagen_firmada" class="border br-12 my-3 obj-cover" style="width:156px;height:156px;" alt="" />
                                </div>
                            </div>
                            <div class="row mx-0 bg-white br-12 py-3">
                                <p v-show="detalle.historial.length" class="col-12 text-general f-600 f-18 mb-2">
                                    Historial de Postulación
                                </p>
                                <div class="col-12 pt-3">
                                    <div v-for="(h,index3) in detalle.historial" :key="`key3${index3}`" class="row mx-0 br-5 bg-whitesmoke2 align-items-center justify-content-between my-3" style="height:30px;">
                                        <div class="col-auto">
                                            <p>
                                                <strong> {{ h.tipo_nombre }}: </strong> {{ h.created_at | helper-fecha('DD-MMM-Y') }}
                                            </p>
                                        </div>
                                        <div v-if="h.id_motivo" class="col-auto ">
                                            <p>
                                                <strong class="text-orange"> Motivo: </strong> {{ h.motivo }}
                                            </p>
                                        </div>
                                        <div v-if="h.justificacion != null" class="col-auto txt-blue f-600 cr-pointer" @click="AbrirDetallesPostulacion(h)">
                                            Ver Detalle
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else class="d-middle-center h-100 bg-white br-b-12">
                    <div class="col-4 text-center">
                        <img height="100" src="/img/no-imagen/click.svg" alt="" />
                        <p>Selecciona una de las postulaciones del panel de la izquierda.</p>
                    </div>
                </div>
            </div>
        </div>
        <!-- Modales -->
        <modal-denegar-Postulacion ref="DenegarPostulacion" @update="update_lista" />
        <modalAprobarPostulacion ref="AprobarPostulacion" @update="update_lista" />
        <modalPublicarTienda ref="PublicarTienda" />
        <modalDetallePostulacion ref="DetallesPostulacion" :detalle="detalle_historial" />
        <modal-imagen ref="modal_imagen" />
        <modalActivarInactivar ref="modalActivarInactivar" @estadoChat="estadoChat" />
        <mapa-google-modal
        ref="modalMapaGoogle"
        :coordenadas-entrada="coordenadas"
        :desactivar="true"
        />
        <!-- Modales -->
    </section>
</template>

<script>
import { mapGetters } from 'vuex'
import Leecheros from "~/services/tienda/tienda"
export default {
    components: {
        modalDenegarPostulacion: () => import('./partials/modalDenegarPostulacion'),
        modalAprobarPostulacion : () => import('./partials/modalAprobarPostulacion'),
        modalPublicarTienda: () => import('./partials/modalPublicarTienda'),
        modalDetallePostulacion : () => import('./partials/modalDetallesPostulacion'),
        modalActivarInactivar: () => import('./partials/modalActivarInactivar')
    },
    data(){
        return{
            chatActivo: true,
            chatInactivo: false,
            buscarTienda: '',
            activo: -1,
            loading:true,
            tiendas:[],
            detalle:{nit_soporte:'',imagenes:[], historial:[]},
            detalle_historial:{},
            coordenadas:{}
        }
    },
    computed: {
        ...mapGetters({
            id_cedis:'cedis/id_cedis',
        })
    },
    watch: {
        async id_cedis(val){
            if (val != null){
                this.listar()
            }
        }

    },
    mounted(){
        if (this.id_cedis != null){
            this.listar()
        }
    },
    methods: {
        async listar(){
            try {
                let params =  { id_cedis: this.id_cedis }
                const {data} = await Leecheros.postulaciones(params)
                this.tiendas = data.tiendas
            } catch (e){
                this.error_catch(e)
            } finally {
                this.loading = false
            }
        },
        async get_detalle(id){
            try {
                const {data} = await Leecheros.detalle(id)
                this.detalle = data.info
                this.coordenadas.lat = +data.info.latitud
                this.coordenadas.lng = +data.info.longitud
                this.$refs.mapaPeq.setPosition()

            } catch (e){
                this.error_catch(e)
            } finally {
                this.loading = false
            }
        },
        openAdjunto(item){
            if(this.file_type(item.nit_soporte) == 'application/pdf'){
                this.set_pdf(item.soporte)
            }else if(this.file_type(item.nit_soporte) == 'image/*'){
                this.$refs.modal_imagen.toggle(item.soporte)
            }
        },
        update_lista(){
            this.listar()
            this.activo = -1
        },
        AbrirDenegarPostulacion(){
            let id = this.detalle.id
            this.$refs.DenegarPostulacion.toggle(id);
        },
        AbrirAprobarPostulacion(){
            // this.$refs.AprobarPostulacion.toggle(id);
            this.$refs.PublicarTienda.toggle(this.detalle);
        },
        AbrirDetallesPostulacion(item){
            this.detalle_historial = item
            this.$refs.DetallesPostulacion.toggle();
        },
        abrirModalMapa(){
            this.$refs.modalMapaGoogle.toggle()
        },
        estadoChat(est){
            if (est){
                this.chatActivo=true
            }else{
                this.chatActivo=false
            }
        }
    }
}
</script>

<style lang="scss">
.br-12{
    border-radius: 12px;
}
.txt-blue{
    color: #0D4DFF;
}
.custom-scroll_tiendas::-webkit-scrollbar-track{
    height: 6px;
    width:10px;
    -webkit-box-shadow:inset 0 0 6px rgba(0,0,0,0.1); background-color:#F5F5F5; border-radius: 5px; }

    .custom-scroll_tiendas::-webkit-scrollbar{
        background-color: var(bg-gr-general);
        width:10px;
        height: 6px;
        border-radius: 5px;
    }
    .custom-scroll_tiendas::-webkit-scrollbar-thumb{
        -webkit-box-shadow:inset 0 0 6px rgba(0,0,0,0.1);
        background:var(--gr-general);
        border-radius: 5px;
    }
    </style>
