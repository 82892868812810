<template>
    <section class="pt-3">
        <div class="row mx-0">
            <div class="px-0 bg-white br-b-12" style="width:410px;">
                <div class="px-3 bg-white br-t-12" style="width:410px;">
                    <div class="row justify-center py-3">
                        <div class="col input">
                            <el-input v-model="buscarTienda" class="w-100 br-20" size="small" :placeholder="`Buscar ${$config.vendedor}`" />
                        </div>
                    </div>
                </div>
                <div class="overflow-auto custom-scroll" style="width:100%;height:calc(100vh - 220px);">
                    <div
                    v-for="(cambio, idx) in cambios.filter(c => !buscarTienda
                        || (c.propietario_nombre && c.propietario_nombre.toLowerCase().includes(buscarTienda.toLowerCase()))
                        || (String(c.telefono_celular).includes(buscarTienda)))"
                    :key="idx"
                    class="row mx-0 d-middle border-bottom cr-pointer"
                    style="height:120px;"
                    @click="activo=cambio.id"
                    >
                        <div :class="`mx-2 px-1 h-90 br-5 ${activo==cambio.id?'bg-general':''}`" />
                        <div class="col-9 tres-puntos f-15">
                            <div class="row mx-0 f-600">
                                <p class="tres-puntos text-general">{{ cambio.propietario_nombre }}</p>
                            </div>
                            <div class="row mx-0 text-general">
                                <p class="mr-2 text-general2">
                                    {{ cambio.created_at | helper-fecha('DD/MMM/Y') }}
                                </p>
                                <div class="bg-general3 d-middle-center text-white text-center f-14 br-12 px-2" style="min-width:60px;height:24px;">
                                    {{ cambio.dias }} Dias
                                </div>
                            </div>
                            <div class="row mx-0">
                                <div class="col pl-0 tres-puntos text-general">
                                    <strong>{{ cambio.pais ? cambio.pais : cambio.pais_original }}, </strong>{{ cambio.ciudad ? cambio.ciudad : cambio.ciudad_original }}
                                </div>
                            </div>
                            <div class="row mx-0">
                                <div class="col pl-0 tres-puntos text-general">
                                    <strong>Telefono: </strong>{{ cambio.telefono_celular }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <content-loader v-if="loading" />
                    <div v-if="!cambios.length" class="row h-100 mx-0 align-items-center justify-content-center">
                        <div class="text-center f-17 f-600 d-middle-center flex-column" style="width:225px;">
                            <div class="d-middle-center bg-whitesmoke br-5" style="width:115px;height:115px;">
                                <img height="110" src="/img/no-imagen/shop.svg" alt="" />
                            </div>
                            <p>Aún no tienes solicitudes para cambios de datos de {{ $config.vendedor + 's.' }}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="activo !== null" class="col px-0 ml-3">
                <div class="row mx-0">
                    <div class="col-12 px-0 bg-white br-t-12">
                        <div v-if="activo !== null" class="row mx-0 pt-3">
                            <div class="col text-general d-middle">
                                <p> Solicitud cambio de datos {{ formatearFecha(info_cambio.created_at,'DD MMM Y') }} <span class="text-general2 f-14 mx-2">{{ info_cambio.antiguedad }}</span></p>
                            </div>
                            <div v-if="$can('botones_leecheros_cambio_datos')" class="col-auto px-0 d-middle-center ml-auto">
                                <div class="btn-outline px-3 mr-2 f-14" @click="AbrirDenegarCambios()">
                                    Denegar
                                </div>
                                <div class="btn-general px-3 f-14" @click="AbrirAprobarCambios()">
                                    Aprobar
                                </div>
                            </div>
                            <div v-if="activo !== null" class="col-auto px-2">
                                <chatIconoChat :id-tienda="info_cambio.id_tienda" />
                            </div>
                        </div>
                    </div>
                    <p class="col-12 py-3 bg-white text-general">
                        Los datos editados y que están pendientes por aprobación se distinguen por la siguiente imagen
                        <i class="icon-alert-triangle f-18 text-orange" />
                    </p>
                </div>
                <div v-if="activo !== null" class="row mx-0 overflow-auto custom-scroll pr-3 pl-0" style="width:100%;height:calc(100vh - 270px);">
                    <div class="col-6 text-general f-17 f-500 py-3">
                        Información original
                    </div>
                    <div class="col-6 text-general f-17 f-500 py-3">
                        Informacion editada
                    </div>
                    <!-- Nombre, fecha y genero -->
                    <div class="col bg-white br-12 py-2">
                        <p class="text-general f-18 f-600">
                            {{ tienda_actual.propietario_nombre }}
                        </p>
                        <div class="row mx-0 py-2">
                            <img :src="tienda_actual.logo_mini_firmado" style="width:162px;height:162px;" class="border br-12" alt="" />
                        </div>
                        <p class="mt-2 text-general">
                            <strong class="f-17"> Fecha nacimiento: </strong>
                            <span class="f-15">
                                {{ tienda_actual.propietario_nacimiento }}
                            </span>
                        </p>
                        <p class="mt-2 text-general">
                            <strong class="f-17"> Genero: </strong>
                            <span class="f-15">
                                {{ tienda_actual.genero_nombre }}
                            </span>
                        </p>
                    </div>
                    <div class="col bg-white br-12 ml-2 py-2">
                        <p class="text-general f-18 f-600">
                            {{
                                tienda_cambio.prop_nombre != null || tienda_cambio.prop_apellido != null ?
                                    `${tienda_cambio.prop_nombre ? tienda_cambio.prop_nombre : tienda_cambio.prop_nombre_original } ${tienda_cambio.prop_apellido ? tienda_cambio.prop_apellido : tienda_cambio.prop_apellido_original }` :
                                    `${tienda_cambio.propietario_nombre}`

                            }}
                            <i v-if="tienda_cambio.prop_nombre != null || tienda_cambio.prop_apellido != null" class="icon-alert-triangle f-18 text-orange" />
                        </p>
                        <div class="row mx-0 py-2">
                            <img :src="tienda_cambio.logo_mini_firmado" style="width:162px;height:162px;" class="border br-12" alt="" />
                        </div>
                        <p class="mt-2 text-general">
                            <strong class="f-17"> Fecha nacimiento: </strong>
                            <span class="f-15">
                                {{ tienda_cambio.propietario_nacimiento }}
                            </span>
                        </p>
                        <p class="mt-2 text-general">
                            <strong class="f-17"> Genero: </strong>
                            <span class="f-15">
                                {{ tienda_cambio.genero_nombre }}
                            </span>
                        </p>
                    </div>
                    <!-- Separador -->
                    <div class="col-12 py-3" />
                    <!-- Separador -->
                    <!-- Descripcion -->
                    <div class="col bg-white br-12 text-general py-3">
                        <p class="f-600 f-17">
                            Descripción de mi:
                        </p>
                        <p class="f-600 f-14">
                            (Esta información la podrá ver tus clientes)
                        </p>
                        <p class="mt-2">
                            {{ tienda_cambio.descripcion }}
                        </p>
                    </div>
                    <div class="col bg-white text-general br-12 ml-3 py-3">
                        <p class="f-600 f-17">
                            Descripción de mi:
                        </p>
                        <p class="f-600 f-14">
                            (Esta información la podrá ver tus clientes):
                        </p>
                        <p class="mt-2">
                            {{ tienda_actual.descripcion }}
                        </p>
                    </div>
                    <!-- Separador -->
                    <div class="col-12 py-3" />
                    <!-- Separador -->
                    <!-- Ubicación -->
                    <div class="col bg-white br-12 text-general py-3">
                        <p class="mt-2">
                            <strong class="f-17"> Ubicación </strong>
                        </p>
                        <p class="mt-2">
                            <strong class="f-17"> {{ tienda_actual.pais }}, </strong>
                            <span class="f-14">
                                {{ tienda_actual.departamento }},
                            </span>
                            <span class="f-14">
                                {{ tienda_actual.ciudad }}
                            </span>
                        </p>
                        <p class="mt-2 f-14">
                            <strong class="f-17"> Código postal: </strong>
                            {{ tienda_actual.codigo_postal }}
                        </p>
                        <p class="mt-2 f-14">
                            <strong class="f-17"> Dirección: </strong>
                            {{ tienda_actual.direccion }}
                        </p>
                        <p class="mt-2 f-14">
                            <strong class="f-17"> Complemento: </strong>
                            {{ tienda_actual.direccion_anexo }}
                        </p>
                        <p class="mt-2 f-14">
                            <strong class="f-17"> Barrio/Colonia: </strong>
                            {{ _.defaultTo(tienda_actual.barrio,'No registra') }}
                        </p>
                        <div class="row mx-0 position-relative my-2">
                            <div class="col px-0 cr-pointer" @click="abrirModalMapa1">
                                <mapa v-if="activo !== null" ref="mapaActualPeq" :coordenadas="coordenadas_actual" />
                                <p class="f-13 mt-2 text-gris2 cr-pointer" @click="abrirModalMapa1()">
                                    Haga click aquí, para ver la ubicación del vendedor
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col bg-white br-12 text-general ml-3 py-3">
                        <p class="mt-2">
                            <strong class="f-17"> Ubicación </strong>
                        </p>
                        <p class="mt-2 f-14">
                            <strong class="f-17"> {{ tienda_cambio.idm_ciudad != null ? tienda_cambio.pais : tienda_cambio.pais_original }}, </strong>
                            {{ tienda_cambio.idm_ciudad != null ? tienda_cambio.departamento : tienda_cambio.departamento_original }},
                            {{ tienda_cambio.idm_ciudad != null ? tienda_cambio.ciudad : tienda_cambio.ciudad_original }}
                            <i v-if="tienda_cambio.idm_ciudad != null" class="icon-alert-triangle f-18 text-orange" />
                        </p>
                        <p class="mt-2 f-14">
                            <strong class="f-17"> Código postal: </strong>
                            {{ tienda_cambio.codigo_postal }}
                        </p>
                        <p class="mt-2 f-14">
                            <strong class="f-17"> Dirección: </strong>
                            {{ tienda_cambio.direccion != null ? tienda_cambio.direccion : tienda_cambio.direccion_original }}
                            <i v-if="tienda_cambio.direccion != null" class="icon-alert-triangle f-18 text-orange" />
                        </p>
                        <p class="mt-2 f-14">
                            <strong class="f-17"> Complemento: </strong>
                            {{ tienda_cambio.direccion_anexo != null ? tienda_cambio.direccion_anexo : _.defaultTo(tienda_cambio.direccion_anexo_original,'No registra') }}
                            <i v-if="tienda_cambio.direccion_anexo != null" class="icon-alert-triangle f-18 text-orange" />
                        </p>
                        <p class="mt-2 f-14">
                            <strong class="f-17"> Barrio/Colonia: </strong>
                            {{ tienda_cambio.barrio != null ? tienda_cambio.barrio : _.defaultTo(tienda_cambio.direccion_anexo_original,'No registra') }}
                            <i v-if="tienda_cambio.barrio != null" class="icon-alert-triangle f-18 text-orange" />
                        </p>
                        <div class="row mx-0 position-relative my-2">
                            <div class="col px-0 px-1 cr-pointer" @click="abrirModalMapa2">
                                <mapa v-if="activo !== null" ref="mapaCambioPeq" :coordenadas="coordenadas_cambio" />
                                <p class="f-13 mt-2 text-gris2 cr-pointer" @click="abrirModalMapa2()">
                                    Haga click aquí, para ver la ubicación del vendedor
                                    <i v-if="tienda_cambio.direccion != null" class="icon-alert-triangle f-18 text-orange" />
                                </p>
                            </div>
                        </div>
                    </div>
                    <!-- Separador -->
                    <div class="col-12 py-3" />
                    <!-- Separador -->
                    <!-- Datos Facturacion persona natural -->
                    <div class="col bg-white br-12 py-3">
                        <p class="text-general f-17 f-600">Datos de facturación</p>
                        <template v-if="tienda_actual.tipo == 1">
                            <p class="text-general mt-2">
                                <strong>Como persona natural</strong>
                            </p>
                            <div class="row py-2 text-general">
                                <div class="col">
                                    <p class="text-general mt-2">
                                        <strong> N.I.T de la tienda: </strong>
                                        {{ _.defaultTo(tienda_actual.nit,'No registra') }}
                                    </p>
                                </div>
                            </div>
                            <div v-if="tienda_actual.nit_soporte" class="row mx-0 my-2">
                                <div id="df_gbp" class="bg-gr-general br-12 d-middle-center text-white px-5 cr-pointer" :class="file_type(tienda_actual.nit_soporte) === 'application/pdf' ? '_df_custom' : ''" style="height:40px;width:380px;" @click="openAdjunto(tienda_actual)">
                                    <i class="icon-doc-inv" />
                                    Adjunto: Soporte Nit
                                </div>
                            </div>
                        </template>
                    </div>
                    <div class="col bg-white br-12 py-3 ml-3">
                        <p class="text-general f-17 f-600">Datos de facturación</p>
                        <template v-if="tienda_cambio.tipo == 1">
                            <p class="text-general mt-2">
                                <strong>Como persona natural</strong>
                            </p>
                            <div class="row mx-0 py-2 text-general">
                                <div class="col">
                                    <p class="text-general mt-2">
                                        <strong> N.I.T de la tienda: </strong>
                                        {{ tienda_cambio.nit != null ? tienda_cambio.nit : _.defaultTo(tienda_cambio.nit_original,'No registra') }}
                                        <i v-if="tienda_cambio.nit != null" class="icon-alert-triangle f-18 text-orange" />
                                    </p>
                                </div>
                            </div>
                            <div v-if="tienda_cambio.nit_soporte" class="row mx-0 my-2">
                                <div id="df_gbp" class="bg-gr-general br-12 d-middle-center text-white px-5 cr-pointer" :class="file_type(tienda_cambio.nit_soporte) === 'application/pdf' ? '_df_custom' : ''" style="height:40px;width:380px;" @click="openAdjunto(tienda_cambio)">
                                    <i class="icon-doc-inv" />
                                    Adjunto: Soporte Nit
                                </div>
                            </div>
                        </template>
                    </div>
                    <!-- Separador -->
                    <div v-if="tienda_actual.tipo == 2" class="col-12 py-3" />
                    <!-- Separador -->
                    <!-- Datos tienda -->
                    <div v-if="tienda_actual.tipo == 2" class="col bg-white br-12 py-3">
                        <p class="text-general mt-2">
                            <strong>Como Tienda</strong>
                        </p>
                        <div class="row mx-0 py-2 text-general">
                            <div class="col">
                                <p class="text-general mt-2">
                                    <strong> Tienda: </strong>
                                    {{ tienda_actual.nombre }}
                                </p>
                                <p class="text-general mt-2">
                                    <strong> N.I.T de la tienda: </strong>
                                    {{ tienda_actual.nit }}
                                </p>
                            </div>
                        </div>
                        <div v-if="tienda_actual.nit_soporte" class="row mx-0 my-2">
                            <div id="df_gbp" class="bg-gr-general br-12 d-middle-center text-white px-5 cr-pointer" :class="file_type(tienda_actual.nit_soporte) === 'application/pdf' ? '_df_custom' : ''" style="height:40px;width:380px;" @click="openAdjunto(tienda_actual)">
                                <i class="icon-doc-inv" />
                                Adjunto: Soporte Nit
                            </div>
                        </div>
                    </div>
                    <div v-if="tienda_cambio.tipo == 2" class="col bg-white br-12 py-3 ml-3">
                        <p class="text-general mt-2">
                            <strong>Como Tienda</strong>
                        </p>
                        <div class="row mx-0 py-2 text-general">
                            <div class="col px-0">
                                <p class="text-general mt-2">
                                    <strong> Tienda: </strong>
                                    {{ tienda_cambio.nombre }}
                                </p>
                                <p class="text-general mt-2">
                                    <strong> N.I.T de la tienda: </strong>
                                    {{ tienda_cambio.nit != null ? tienda_cambio.nit : _.defaultTo(tienda_cambio.nit_original,'No registra') }}
                                    <i v-if="tienda_cambio.nit != null" class="icon-alert-triangle f-18 text-orange" />
                                </p>
                            </div>
                        </div>
                        <div v-if="tienda_cambio.nit_soporte" class="row mx-0 my-2">
                            <div id="df_gbp" class="bg-gr-general br-12 d-middle-center text-white cr-pointer" :class="file_type(tienda_cambio.nit_soporte) === 'application/pdf' ? '_df_custom' : ''" style="height:40px;width:380px;" @click="openAdjunto(tienda_cambio)">
                                <i class="icon-doc-inv" />
                                Adjunto: Soporte Nit
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else-if="!activo && cambios.length" class="col ml-3 bg-white br-12 d-middle-center">
                <div class="text-center f-17 f-600 py-2" style="width:225px;">
                    <div class="bg-whitesmoke br-8 py-3">
                        <img height="100" src="/img/no-imagen/click.svg" alt="" />
                    </div>
                    <p class="mt-3">Selecciona una de las solicitudes del panel de la izquierda.</p>
                </div>
            </div>
        </div>
        <!-- Partials -->
        <modal-denegar-cambios ref="DenegarCambios" @actualizar="getDatos" />
        <modal-aprobar-cambios ref="AprobarCambios" @actualizar="getDatos" />
        <modal-imagen ref="modal_imagen" />
        <mapa-google-modal
        ref="modalMapaGoogle1"
        :coordenadas-entrada="coordenadas_actual"
        :desactivar="true"
        />
        <mapa-google-modal
        ref="modalMapaGoogle2"
        :coordenadas-entrada="coordenadas_cambio"
        :desactivar="true"
        />
    </section>
</template>

<script>
import { mapGetters } from 'vuex';
import CambioDatos from "../../../services/tiendas/cambio_datos";
export default {
    components: {
        modalDenegarCambios : () => import('./partials/modalDenegarCambios'),
        modalAprobarCambios : () => import('./partials/modalAprobarCambios')
    },
    data(){
        return{
            buscarTienda: '',
            activo: null,
            loading: true,
            cambios: [],
            info_cambio: {
                id: null,
                id_tienda: null,
                antiguedad: null,
                created_at: null,
            },
            coordenadas_actual: { lat: 0, lng: 0 },
            coordenadas_cambio: { lat: 0, lng: 0 },
            tienda_actual: {
                barrio:null,
                ciudad: null,
                departamento: null,
                descripcion:null,
                direccion:null,
                direccion_anexo:null,
                estado:null,
                id:null,
                idm_ciudad:null,
                idm_estado:null,
                idm_pais:null,
                latitud:0,
                logo:null,
                logo_mini:null,
                logo_mini_firmado:null,
                longitud:0,
                nit:null,
                nit_soporte:null,
                nit_soporte_firmado:null,
                nombre:null,
                propietario_nombre:null,
                telefono_celular:null,
            },
            tienda_cambio: {
                barrio: null,
                created_at: null,
                ciudad: null,
                departamento: null,
                descripcion: null,
                direccion: null,
                direccion_anexo: null,
                estado: null,
                id: null,
                id_tienda: null,
                idm_ciudad: null,
                idm_estado: null,
                idm_pais: null,
                justificacion: null,
                latitud: 0,
                logo: null,
                logo_mini: null,
                logo_mini_firmado: null,
                longitud: 0,
                nit: null,
                nit_soporte: null,
                nombre: null,
                propietario_nombre: null,
                telefono_celular: null,
                updated_at: null,
            }
        }
    },
    computed: {
        ...mapGetters({
            id_cedis:'cedis/id_cedis',
        })
    },
    watch: {
        async id_cedis(val){
            if (val != null){
                this.getDatos()
            }
        },
        activo(val){
            if (val !== null){
                let cambio = this.cambios.find(c => c.id == val)
                this.info_cambio.id = cambio.id
                this.info_cambio.id_tienda = cambio.id_tienda
                this.info_cambio.created_at = cambio.created_at
                this.info_cambio.antiguedad = cambio.antiguedad
                this.tienda_actual = cambio.tienda_actual
                this.coordenadas_actual.lat = +this.tienda_actual.latitud
                this.coordenadas_actual.lng = +this.tienda_actual.longitud
                this.tienda_cambio = cambio.tienda_cambio
                this.coordenadas_cambio.lat = +this.tienda_cambio.latitud ? +this.tienda_cambio.latitud : +this.tienda_cambio.latitud_original
                this.coordenadas_cambio.lng = +this.tienda_cambio.longitud ? +this.tienda_cambio.longitud : +this.tienda_cambio.longitud_original
                setTimeout(() => {
                    this.$refs.mapaActualPeq.setPosition()
                    this.$refs.mapaCambioPeq.setPosition()
                }, 500);
            }
        }
    },
    mounted(){
        if (this.id_cedis != null){
            this.getDatos()
        }
    },
    methods: {
        AbrirDenegarCambios(){
            this.$refs.DenegarCambios.toggle(this.info_cambio);
        },
        AbrirAprobarCambios(){
            this.$refs.AprobarCambios.toggle(this.info_cambio);
        },
        async getDatos(){
            try {
                let params =  { id_cedis: this.id_cedis }

                const { data } = await CambioDatos.getDatos(params)
                this.cambios = data.data
                this.loading = false
                this.activo = null
            } catch (e){
                this.error_catch(e)
            }
        },
        abrirModalMapa1(){
            this.$refs.modalMapaGoogle1.toggle()
        },
        abrirModalMapa2(){
            this.$refs.modalMapaGoogle2.toggle()
        },
        openAdjunto(item){
            if(this.file_type(item.nit_soporte) == 'application/pdf'){
                this.set_pdf(item.nit_soporte_firmado)
            }else if(this.file_type(item.nit_soporte) == 'image/*'){
                this.$refs.modal_imagen.toggle(item.nit_soporte_firmado)
            }
        },
        verMapa(){
            // console.log('primero');
            // this.$refs.modalVerMapa.toggle()
        },

    }
}
</script>

<style lang="scss">
.br-12{
    border-radius: 12px;
}
.input{
    .el-input{
        .el-input__inner{
            border-radius: 30px !important;
            text-align: center;
        }
    }
}
/* .custom-scroll_tiendas::-webkit-scrollbar-track{
    height: 6px;
    width:10px;
    -webkit-box-shadow:inset 0 0 6px rgba(0,0,0,0.1); background-color:#F5F5F5; border-radius: 5px; }

.custom-scroll_tiendas::-webkit-scrollbar{
    background-color: var(bg-gr-general);
    width:10px;
    height: 6px;
    border-radius: 5px;
}
.custom-scroll_tiendas::-webkit-scrollbar-thumb{
    -webkit-box-shadow:inset 0 0 6px rgba(0,0,0,0.1);
     background:var(--gr-general);
     border-radius: 5px;
} */
</style>
