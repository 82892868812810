<template>
    <section class="section-view">
        <div class="row mx-0 py-2 align-items-center">
            <div class="d-middle cr-pointer" @click="regresar">
                <i class="icon-left-circle f-22 text-general" />
                <img class="border br-4 obj-cover mx-2" width="36" height="36" :src="tienda.logo_mini_firmado" alt="" />
                <p class="text-general f-600 f-18 text text-2 lh-20" style="width:300px;">
                    {{ tienda.propietario_nombre }}
                </p>
            </div>
            <chatIconoChat class="ml-auto" :id-tienda="parseInt(this.$route.params.id_tienda)" />
            <!-- <div class="d-middle justify-content-end">
            </div> -->
        </div>
        <tabs :tabs="tabs" class="border-bottom" />
        <router-view />
    </section>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
    data(){
        return {
            tabsReales: [
                { titulo: 'Información', ruta: 'admin.tiendas.produccion.informacion' },
                { titulo: 'Productos', ruta: 'admin.tiendas.produccion.productos', can: 'tab2_leecheros_productos' },
                { titulo: 'Compras', ruta: 'admin.tiendas.produccion.abastecimiento' },
                { titulo: 'Ventas', ruta: 'admin.tiendas.produccion.metricas' },
                { titulo: 'Calificaciones', ruta: 'admin.tiendas.produccion.calificaciones' },
                { titulo: 'Clientes', ruta: 'admin.tiendas.produccion.clientes', can: 'tab2_leecheros_clientes' },
                //{ titulo: 'Creditos', ruta: 'admin.tiendas.produccion.creditos', can: 'tab2_leecheros_creditos' },
                // { titulo: 'Comisiones', ruta: 'admin.tiendas.produccion.comisiones', can: 'tab2_leecheros_comisiones' },
                //{ titulo: 'Comisiones', ruta: 'admin.tiendas.produccion.alianzas', can: 'tab2_leecheros_alianzas' },
                { titulo: 'Parámetros', ruta: 'admin.tiendas.produccion.parametros', can: 'tab2_leecheros_parametros' },
                { titulo: 'Identificadores', ruta: 'admin.tiendas.produccion.identificadores', can: 'tab2_leecheros_identificadores' },
                { titulo: 'Pedidos', ruta: 'admin.tiendas.produccion.pedidos', can: 'tab2_leecheros_pedidos' },
                { titulo: 'Estados', ruta: 'admin.tiendas.produccion.estado-leechero', can: 'tab2_leecheros_estados' },
                // { titulo: 'Creditos', ruta: 'tendero.almacen.creditos.dashboard' },
            ]

        }
    },
    computed: {
        ...mapGetters({
            tienda: 'tiendas/info_cabecera/tienda'
        }),
        tabs(){
            return this.tabsReales
        }
    },
    watch: {
        "$route.name"(){
            this.$store.commit('cedis/setCargandoTerminado', true)
        }
    },
    mounted(){
        this.info_cabecera();
        this.verificarCredito();
    },
    methods: {
        regresar(){
            this.$router.push({ name: 'admin.tiendas.produccion' })
        },
        async info_cabecera(){
            await this.$store.dispatch('tiendas/info_cabecera/action_info_cabecera', this.$route.params.id_tienda)
        },
        verificarCredito(){
            if(`${this.$config.menu.creditos}` == 0){
                this.tabsReales.splice(6, 1)
            }
        }
    }
}
</script>

<style lang="css" scoped>
</style>
