<template>
    <section class="pt-3">
        <div class="row mx-0">
            <div class="px-0" style="width:410px;">
                <div class="row mx-0 justify-center py-3 bg-white br-t-12">
                    <div class="input" style="width:366px;">
                        <el-input v-model="buscarTienda" class="w-100 br-20" size="small" :placeholder="`Buscar ${$config.vendedor}`" />
                    </div>
                </div>
                <div class="overflow-auto custom-scroll br-b-12 bg-white" style="width:100%;height:calc(100vh - 225px);">
                    <div
                    v-for="(t, idx) in tiendas.filter(el => !buscarTienda  
                        || el.propietario_nombre && (el.propietario_nombre.toLowerCase().includes(buscarTienda.toLowerCase())) 
                        || el.propietario_nombre && String(el.telefono).includes(buscarTienda.toLowerCase()))" 
                    :key="idx"
                    class="row mx-0 d-middle border-bottom cr-pointer"
                    style="height:120px;"
                    @click="get_detalle(t.id), activo = idx"
                    >
                        <div :class="`mx-2 px-1 h-80 br-5 ${activo==idx?'bg-general':''}`" />
                        <div class="col ml-2 px-0 tres-puntos f-15">
                            <div class="row mx-0 f-600 text-general">
                                <p class="tres-puntos">{{ t.propietario_nombre ? t.propietario_nombre : '----' }}</p>
                            </div>
                            <div class="row mx-0 align-items-center">
                                <p class="mr-2 text-general2 f-300">
                                    {{ t.updated_at | helper-fecha('DD/MMM/Y') }}
                                </p>
                                <div class="d-middle-center px-2 bg-general3 f-14 text-white px-1 br-12" style="height:24px;">
                                    {{ t.dias }} Dias
                                </div>
                            </div>
                            <div class="row mx-0 text-general mt-1">
                                <div class="col pl-0 tres-puntos">
                                    <strong>{{ t.pais }}, </strong>{{ t.ciudad }}
                                </div>
                            </div>
                            <div class="row mx-0 text-general">
                                <div class="col pl-0 tres-puntos">
                                    <strong>Telefono: </strong>{{ t.telefono }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <content-loader v-show="loading" />
                </div>
            </div>
            <div class="col px-0 ml-3">
                <div v-if="activo > -1" class="row mx-0 py-3 px-3 bg-white br-12 mb-2">
                    <div class="d-middle text-general f-17">
                        Rechazado {{ detalle.updated_at | helper-fecha('DD MMM Y') }}
                    </div>
                    <div v-if="detalle.estado == 3" class="mx-1 d-middle-center ml-auto">
                        <div v-if="$can('boton_leecheros_publicar_denegado')" class="btn-general bg-general d-middle-center text-white f-15 p-1 px-5 br-8 mx-2 cr-pointer" @click="AbrirAprobarPostulacion()">
                            Aprobar
                        </div>
                    </div>
                    <!-- Cancelado por postulante -->
                    <div v-if="detalle.estado == 2 == true" class="mx-3 text-general f-600 f-17 d-middle-center ml-auto">
                        Cancelado por postulante
                    </div>
                </div>
                <div v-if="activo > -1" class="overflow-auto custom-scroll" style="width:100%;height:calc(100vh - 225px);">
                    <div class="row mx-0">
                        <div class="col-12 px-0">
                            <div class="row mx-0 px-3 mb-2 bg-white br-12 py-3">
                                <img :src="detalle.imagen" class="br-12 border obj-cover" style="width:162px;height:162px;" />
                                <div class="col">
                                    <p class="mb-0 text-general f-600 my-3 f-22">
                                        {{ detalle.propietario_nombre }}
                                    </p>
                                    <p class="text-general mt-2">
                                        <strong> Fecha nacimiento: </strong>
                                        {{ detalle.propietario_nacimiento }}
                                    </p>
                                    <p class="text-general mt-2">
                                        <strong> Genero: </strong>
                                        {{ detalle.genero_nombre }}
                                    </p>
                                </div>
                            </div>
                            <div class="row mx-0 my-3 bg-white br-12 py-3">
                                <div class="col-12">
                                    <p class="text-general mt-2">
                                        <strong> Contacto </strong>
                                    </p>
                                    <p class="text-general mt-2">
                                        <strong> Número de telefono: </strong>
                                        {{ detalle.telefono }}
                                    </p>
                                    <p class="text-general mt-2">
                                        <strong>Dirección de correo electrónico: </strong>
                                        {{ detalle.correo }}
                                    </p>
                                </div>
                                <div class="col-auto px-2 mt-2 f-600 text-general">
                                    Descripción:
                                </div>
                                <div class="col-9 px-0 mt-2 mx-1 text-general">
                                    {{ detalle.descripcion }}
                                </div>
                            </div>
                            <div class="row mx-0 my-3 bg-white br-12 py-3">
                                <p class="col-12 text-general text-center">
                                    <i class="icon-map-marker-outline text-general f-20" />
                                    {{ detalle.direccion }}
                                </p>
                                <!-- <div class="col">
                                    <p class="text-general mt-2">
                                        <strong> Ubicación </strong>
                                    </p>
                                    <p class="text-general mt-2">
                                        <strong> {{ detalle.pais }}, </strong>
                                        {{ detalle.departamento }},
                                        {{ detalle.ciudad }}
                                    </p>
                                </div> -->
                                <div class="col-12 mx-0 mt-3 justify-center">
                                    <div class="row mx-0 justify-center">
                                        <p class="col-auto text-general">
                                            <strong> Complemento: </strong>
                                            {{ _.defaultTo(detalle.direccion_anexo,'No registra') }}
                                        </p>
                                        <p class="col-auto text-general">
                                            <strong> Barrio / Colonia: </strong>
                                            {{ _.defaultTo(detalle.barrio,'No registra') }}
                                        </p>
                                        <p class="col-auto text-general">
                                            <strong> Cobertura: </strong>
                                            {{ agregarSeparadoresNumero(detalle.cobertura) }} metros
                                        </p>
                                    </div>
                                </div>
                                <div class="col-12 position-relative my-2 cr-pointer" @click="abrirModalMapa">
                                    <mapa ref="mapaPeq" :coordenadas="coordenadas" />
                                </div>
                            </div>
                            <template v-if="detalle.nombre">
                                <div class="row mx-0 mt-5">
                                    <div class="col">
                                        <p class="text-muted2 mt-2">
                                            <strong> Tienda: </strong>
                                            {{ detalle.nombre }}
                                        </p>
                                        <p class="text-muted2 mt-2">
                                            <strong> N.I.T de la tienda: </strong>
                                            {{ _.defaultTo(detalle.nit,'No registra') }}
                                        </p>
                                    </div>
                                </div>
                                <div v-if="detalle.nit_soporte" class="row mx-0 px-5 my-2">
                                    <div id="df_gbp" class="bg-gr-general br-12 d-middle-center text-white px-5 cr-pointer" :class="file_type(detalle.nit_soporte) === 'application/pdf' ? '_df_custom' : ''" style="height:40px;width:380px;" @click="openAdjunto(tienda)">
                                        <i class="icon-doc-inv" />
                                        Adjunto: Soporte Nit
                                    </div>
                                </div>
                            </template>
                            <div class="row mx-0 bg-white br-12 py-3">
                                <p class="col-12 text-general f-18 f-600">
                                    Galería
                                </p>
                                <div v-for="(img,index2) in detalle.imagenes" :key="index2" class="col">
                                    <img :src="img.imagen_firmada" class="border br-12 my-3 obj-cover" style="width:156px;height:156px;" alt="" />
                                </div>
                            </div>
                            <div class="row mx-0 bg-white br-12 py-3 mt-3">
                                <p v-show="detalle.historial.length" class="col-12 text-general f-600 f-18 mb-3">
                                    Historial de Postulación
                                </p>
                                <div class="col-12">
                                    <div v-for="(h,index3) in detalle.historial" :key="`key3${index3}`" class="row mx-0 br-5 bg-whitesmoke2 align-items-center justify-content-between my-3" style="height:30px;">
                                        <div class="col-auto">
                                            <p>
                                                <strong> {{ h.tipo_nombre }}: </strong> {{ h.created_at | helper-fecha('DD-MMM-Y') }}
                                            </p>
                                        </div>
                                        <div v-if="h.id_motivo" class="col-auto ">
                                            <p>
                                                <strong class="text-orange"> Motivo: </strong> {{ h.motivo }}
                                            </p>
                                        </div>
                                        <div v-if="h.justificacion != null" class="col-auto text-general f-600 cr-pointer" @click="AbrirDetallesPostulacion(h)">
                                            Ver Detalle
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else class="d-middle-center h-100 bg-white br-12">
                    <div class="col-4 text-center">
                        <img height="100" src="/img/no-imagen/click.svg" alt="" />
                        <p>Selecciona uno de los {{ $config.vendedor }} denegados del panel de la izquierda.</p>
                    </div>
                </div>
            </div>
        </div>
        <!-- Modales -->
        <modalDetallesPostulacion ref="DetallesPostulacion" :detalle="detalle_historial" />
        <modalAprobarPostulacion ref="AprobarPostulacion" @update="update_lista" />
        <modal-imagen ref="modal_imagen" />
        <modalPublicarTienda ref="PublicarTienda" />
        <mapa-google-modal
        ref="modalMapaGoogle"
        :coordenadas-entrada="coordenadas"
        :desactivar="true"
        />
    </section>
</template>

<script>
import { mapGetters } from 'vuex'
import Leecheros from "~/services/tienda/tienda"
export default {
    components: {
        modalDetallesPostulacion: () => import('./partials/modalDetallesPostulacion'),
        modalPublicarTienda: () => import('./partials/modalPublicarTienda'),
        modalAprobarPostulacion : () => import('./partials/modalAprobarPostulacion')
    },
    data(){
        return{
            cancelado: false,
            buscarTienda: '',
            activo: -1,
            loading:true,
            tiendas:[],
            detalle:{nit_soporte:'',imagenes:[], historial:[]},
            detalle_historial:{},
            coordenadas:{}
        }
    },
    computed: {
        ...mapGetters({
            id_cedis:'cedis/id_cedis',
        })
    },
    watch: {
        async id_cedis(){
            if (this.id_cedis != null){
                this.listar()
            }
        }
    },
    mounted(){
        if (this.id_cedis != null){
            this.listar()
        }
    },
    methods: {
        async listar(){
            try {
                let params =  { id_cedis: this.id_cedis }
                const {data} = await Leecheros.denegados(params)
                this.tiendas = data.tiendas
            } catch (e){
                this.error_catch(e)
            } finally {
                this.loading = false
            }
        },
        async get_detalle(id){
            try {
                const {data} = await Leecheros.detalle(id)
                this.detalle = data.info
                this.coordenadas.lat = +data.info.latitud
                this.coordenadas.lng = +data.info.longitud
                this.$refs.mapaPeq.setPosition()

            } catch (e){
                this.error_catch(e)
            } finally {
                this.loading = false
            }
        },
        openAdjunto(item){
            if(this.file_type(item.nit_soporte) == 'application/pdf'){
                this.set_pdf(item.soporte)
            }else if(this.file_type(item.nit_soporte) == 'image/*'){
                this.$refs.modal_imagen.toggle(item.soporte)
            }
        },
        update_lista(){
            this.listar()
            this.activo = -1
        },
        AbrirDetallesPostulacion(item){
            this.detalle_historial = item
            this.$refs.DetallesPostulacion.toggle();
        },
        AbrirAprobarPostulacion(){
            // let id = this.detalle.id
            this.$refs.PublicarTienda.toggle(this.detalle);
            // this.$refs.AprobarPostulacion.toggle(id);
        },
        abrirModalMapa(){
            this.$refs.modalMapaGoogle.toggle()
        },
    }
}
</script>

<style lang="scss">
.br-12{
    border-radius: 12px;
}
.txt-blue{
    color: #0D4DFF;
}
</style>
